<template>
  <div class="nobleLv5">
    <div class="nobleImg">
      <div class="imgBox">
        <div :class="active===1 ? 'kingBox nochecked':'kingBox'" @click="changeActive(0)">
          <img src="../../assets/imgs/noble/lv5.png" alt="">
        </div>
        <div :class="active===0 ? 'queenBox nochecked':'queenBox'" @click="changeActive(1)">
          <img src="../../assets/imgs/noble/lv5_queen.png" alt="">        
        </div>
      </div>
      <span class="descText">Aristocracy icon for {{ active===0 ? 'male' : active===1 ? 'female' : '' }} </span>  
      <span class="topDesc">
        <img src="../../assets/imgs/noble/line_left.png" alt="">
        <span class="name">Privileges {{hasAuthArr.length}}/{{list.length}}</span>
        <img src="../../assets/imgs/noble/line_right.png" alt="">
      </span>
    </div>
    <div class="displayFun">
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <span v-if="index<=16" @click="a(index)">
            <img :src="item.img" alt="">
            <span class="helpful">{{item.name}}</span>
          </span>
          <span v-else>
            <img :src="item.img" alt="">
            <span>{{item.name}}</span>
          </span>
        </li>
      </ul>
    </div>
    <van-popup v-model="show">
      <div class="lunbo">
        <div class="dis">
          <van-swipe class="my-swipe" indicator-color="white" :initial-swipe="lunboIndex" >
            <template v-for="(item,index) in list" >
              <van-swipe-item :key="index" v-if="item.gift!==undefined">
                <div class="content">
                  <span v-if="item.showBg===true" class="showBg">
                    <img :src="item.gift" alt="">
                  </span>
                  <span class="noBg" v-else> <img :src="item.gift" alt=""></span>
                  <span class="name">{{item.title!=='' && item.title!==null && item.title!==undefined && item.title ? item.title : item.name}}</span> 
                  <span class="text">{{item.content}}</span>
                </div>
              </van-swipe-item>
            </template>
          </van-swipe>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import SVGA from 'svgaplayerweb';
export default {
  name: 'nobleLv5',
  props: {
    title: {
      type: String,
      default: 'King'
    }
  },
  data () {
    return {
      active: 0,
      list: [
        {
          id: 0,
          img: require('../../assets/imgs/noble/g1.png'),
          name: 'King ICON',
          gift: require('../../assets/imgs/noble/lv5/lv5_gift1_king.png'),
          showBg: true,
          content: 'It will be displayed on: your mini data card, chat in the room, friends list, friends request, leaderboards... ',
        },
        {
          id: 1,
          img: require('../../assets/imgs/noble/g2.png'),
          name: 'Mic Frame',
          gift: require('../../assets/imgs/noble/lv5/lv5_gift2_king.png'),
          showBg: true,
          content: 'You can use this frame. You can manage it in "My Decorations"'
        },
        {
          id: 2,
          img: require('../../assets/imgs/noble/g3.png'),
          name: 'Higher Ranking',
          gift: require('../../assets/imgs/noble/g3.png'),
          showBg: false,
          content: 'Higher ranking in online user list of  room'
        },
        {
          id: 3,
          img: require('../../assets/imgs/noble/g4.png'),
          name: 'Room Owner Rewards',
          gift: require('../../assets/imgs/noble/g4.png'),
          title: 'Room Owner Rewards', //点击详情标题与原页面展示标题不一致时这里填详情标题，如果一致就不用填
          showBg: false,
          content: 'As room owner, you can get 4.5% commission from gifts sent in your room'
        },
        {
          id: 4,
          img: require('../../assets/imgs/noble/g5.png'),
          name: 'Entrance Effect',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv5/lv5_gift3_king.png'),
          content: 'You can show the entrance effect when entering into any room'
        },
        {
          id: 5,
          img: require('../../assets/imgs/noble/g6.png'),
          name: 'Room Lock',
          showBg: false,
          gift: require('../../assets/imgs/noble/g6.png'),
          content: 'Lock your room, others can enter only if they have the password'
        },
        {
          id: 6,
          img: require('../../assets/imgs/noble/g17.png'),
          name: 'Live Room',
          showBg: false,
          gift: require('../../assets/imgs/noble/g17.png'),
          content: 'Create live room'
        },
        {
          id: 7,
          img: require('../../assets/imgs/noble/g7.png'),
          name: 'High Speed Upgrade',
          showBg: false,
          gift: require('../../assets/imgs/noble/g7.png'),
          content: '1.4 time faster experience'
        },
        {
          id: 8,
          img: require('../../assets/imgs/noble/g8.png'),
          name: 'Colorful Message',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv5/lv5_gift4_king.png'),
          content: 'Your messages will be colourful'
        },
        {
          id: 9,
          img: require('../../assets/imgs/noble/g21.png'),
          name: 'GIF Pictures',
          showBg: true,
          gift: require('../../assets/imgs/noble/g21.png'),
          content: 'Upload GIF pictures for avatar, user profile theme, room theme, hashtag cover...'
        },
        {
          id: 10,
          img: require('../../assets/imgs/noble/g16.png'),
          name: "Friend's Rooms",
          showBg: false,
          gift: require('../../assets/imgs/noble/g16.png'),
          content: 'You can access friends’s rooms in "Mine-Friends" page easily'
        },
        {
          id: 11,
          img: require('../../assets/imgs/noble/g9.png'),
          name: 'Ride',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv5/lv5_gift5.png'),
          content: 'You can show the ride when entering any room. You can manage it in "My Decorations"'
        },
        {
          id: 12,
          img: require('../../assets/imgs/noble/g10.png'),
          name: 'Hide Country',
          showBg: false,
          gift: require('../../assets/imgs/noble/g10.png'),
          content: 'Your country will be shown as "unknown country" in your profile'
        },
        {
          id: 13,
          img: require('../../assets/imgs/noble/g15.png'),
          name: 'Sign-in of King', //Aristocracy Sign-in
          gift: require('../../assets/imgs/noble/g15.png'),
          title: 'Sign-in of King',
          content: 'Good amount of daily sign-in coins'
        },
        {
          id: 14,
          img: require('../../assets/imgs/noble/g11.png'),
          name: 'Exclusive Gift',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv5/lv5_gift6_king.png'),
          content: 'Only King and above can send this gift'
        },
        {
          id: 15,
          img: require('../../assets/imgs/noble/g12.png'),
          name: 'Upload Room Themes',
          showBg: false,
          gift: require('../../assets/imgs/noble/g12.png'),
          content: 'Upload room themes, and can use all room themes freely'
        },
        {
          id: 16,
          img: require('../../assets/imgs/noble/g13.png'),
          name: 'Anti-kick',
          gift: require('../../assets/imgs/noble/g13.png'),
          content: 'No one can kick you out of room'
        },
        {
          id: 17,
          img: require('../../assets/imgs/noble/g14.png'),
          name: 'Anti-ban of Text',
          gift: require('../../assets/imgs/noble/g14.png'),
          content: 'No one can ban you from text chat in the room'
        },
        { 
          id: 18,
          img: require('../../assets/imgs/noble/g18_2.png'),
          name: 'Unique Mic',
        },
        {
          id: 19,
          img: require('../../assets/imgs/noble/g19_2.png'),
          name: 'Unique User Name',
        },
        {
          id: 20,
          img: require('../../assets/imgs/noble/g20_2.png'),
          name: 'Hide Room',
        }

      ],
      show: false,
      lunboIndex: 0,
      tabTitle: this.title,
      queenData: [],
      hasAuthArr: []
    }
  },
  watch: {
    active(newval,olval){
      this.active = newval      
      let _title = this.active === 0 ? 'King' : (this.active === 1 ? 'Queen' : 'King');
      this.$emit('changeTabtitle', _title)
    }
  },
  created () {
    this.list.forEach(val => {if(val.gift && val.gift !== undefined) this.hasAuthArr.push(val)});
  },
  methods: {
    a (index) {
      this.show = true
      this.lunboIndex = index
    },
    changeActive(index){
      this.active = index
      this.changeData()
    },
    changeData(){
      this.list.forEach((val,key) => {
        if(val.id === 0) {
          val.name = this.active === 0 ? 'King ICON' : 'Queen Icon'
          val.gift = this.active === 0 ? require('../../assets/imgs/noble/lv5/lv5_gift1_king.png') : require('../../assets/imgs/noble/lv5/lv5_gift1_queen.png')
        } else if(val.id === 1) {
          val.gift = this.active === 0 ? require('../../assets/imgs/noble/lv5/lv5_gift2_king.png') : require('../../assets/imgs/noble/lv5/lv5_gift2_queen.png')
        } else if(val.id === 4){
          val.gift = this.active === 0 ? require('../../assets/imgs/noble/lv5/lv5_gift3_king.png') : require('../../assets/imgs/noble/lv5/lv5_gift3_queen.png')
        } else if(val.id === 8){
          val.gift = this.active === 0 ? require('../../assets/imgs/noble/lv5/lv5_gift4_king.png') : require('../../assets/imgs/noble/lv5/lv5_gift4_queen.png')
        } else if(val.id === 13){
          val.name = val.title = this.active === 0 ? 'Sign-in of King' : 'Sign-in of Queen'
        } else if(val.id === 14){
          val.content= this.active === 0 ? 'Only King and above can send this gift' : 'Only Queen and above can send this gift' 
          val.gift = this.active === 0 ? require('../../assets/imgs/noble/lv5/lv5_gift6_king.png') : require('../../assets/imgs/noble/lv5/lv5_gift6_queen.png')
        }
      })
    }
  }
}
</script>

<style lang="scss">
.nobleLv5 {
  #svgaBox {
    width: 20vw;
    height: 20vw;
  }
  .nobleImg {
    color: #333;
    font-size: 14px;
    margin-top: 5vw;
    .imgBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img {
        width: 132px;
        height: 132px;
      }
      img:last-child {
        margin-left: 16px;
      }
    }
    .nochecked {
      opacity: .5;
    }
    .descText {
      font-weight: 400;
      color: #E3C294;
      margin-top: 2px;
    } 
    .topDesc {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 64.71px;
        height: 8.41px;
      }
      .name {
        display: block;
        margin: 0 7px;
      }
    }
    span {
      display: block;
      font-weight: bold;
      white-space: nowrap;
    }
    img {
      width: 40vw;
    }
  }
  .displayFun {
    padding-bottom: 25vw;
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 90vw;
      margin: auto;
      margin-top: 4vw;
      li {
        width: 30vw;
        padding: 2vw 0;
        img {
          width: 8vw;
        }
        span {
          color: #959aa6;
          font-size: 12px;
          display: block;
          width: 20vw;
          margin: auto;
          margin-top: 1vw;
        }
        .helpful {
          color: #b08b55;
        }
      }
    }
  }
  .van-popup {
    background: none;
  }
  .van-popup--center {
    height: 80vw;
  }
  .lunbo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .dis {
      background: #fff;
      width: 80vw;
      border-radius: 4vw;
      position: relative;
    }

    .van-swipe {
      position: initial;
    }

    .my-swipe .van-swipe-item {
      color: #333;
      text-align: center;
      border-radius: 4vw;
    }
    .van-swipe__indicators {
      bottom: -5vw;
    }
    .van-swipe__indicator {
      width: 5px;
      height: 5px;
    }
    .van-swipe__indicator--active {
      width: 13px;
      border-radius: 5px;
    }
    .content {
      .showBg {
        width: 65vw;
        background: url("../../assets/imgs/noble/gift_bg.png") no-repeat;
        background-size: 100%;
        margin: auto;
        margin-top: 7vw;
        img {
          height: 16vw;
          padding: 1vw 0;
        }
      }
      .noBg {
        img {
          height: 18vw;
          margin-top: 8vw;
        }
      }
      span {
        display: block;
      }
      .name {
        color: #333333;
        font-size: 18px;
        padding: 4vw;
        font-weight: bold;
      }
      .text {
        color: #6d6f73;
        font-size: 15px;
        width: 70vw;
        margin: auto;
        padding-bottom: 5vw;
      }
    }
  }
}
</style>